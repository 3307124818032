
@import "./animate.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;  

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f8f8f8;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

#root {
  height: 100%;
}

.sans {font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;}
.btn {
    @apply py-10;
}

.space-jam > * + * {
  @apply ml-4;
}

/* 

  Animation utilities for animate.css

*/

.delay-halfs {
  animation-delay: 0.5s;
}

.delay-quarters {
  animation-delay: 0.25s;
}

.fastest {
  animation-duration: 250ms;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}

.StripeElement--focus {
  outline: none;
  border: 2px solid #3D79FF;
}


/* Generic Styles */
a {
  text-decoration: underline;
}

label {
  cursor: pointer;
}


/* Override  */
.trustime {
  font-family: 'trustime' !important;
}

.ui.dimmer {
  @apply bg-overlay;
}

[tabIndex="0"]:focus {
  outline: none;
}