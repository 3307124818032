.trustime-rotate(@degrees, @rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=@rotation);
  -webkit-transform: rotate(@degrees);
  -moz-transform: rotate(@degrees);
  -ms-transform: rotate(@degrees);
  -o-transform: rotate(@degrees);
  transform: rotate(@degrees);
}

.trustime-flip(@horiz, @vert, @rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=@rotation);
  -webkit-transform: scale(@horiz, @vert);
  -moz-transform: scale(@horiz, @vert);
  -ms-transform: scale(@horiz, @vert);
  -o-transform: scale(@horiz, @vert);
  transform: scale(@horiz, @vert);
}
