/*------------------------
	icons
-------------------------*/
@trustime-spinner: "\ea02";
@trustime-verified: "\ea03";
@trustime-check: "\ea04";
@trustime-privacy: "\ea0a";
@trustime-pdf: "\ea0c";
@trustime-jpg: "\ea0d";
@trustime-png: "\ea0e";
@trustime-zip: "\ea0f";
@trustime-doc: "\ea10";
@trustime-docx: "\ea11";
@trustime-logout: "\ea12";
@trustime-question: "\ea13";
@trustime-cogwheel: "\ea14";
@trustime-upload-file: "\ea15";
@trustime-bulb-63: "\ea16";
@trustime-nav-up: "\ea17";
@trustime-nav-down: "\ea18";
@trustime-a-edit: "\ea19";
@trustime-key: "\ea1a";
@trustime-bill: "\ea1b";
@trustime-credit-card: "\ea1c";
@trustime-users-wm: "\ea1d";
@trustime-add-29: "\ea1e";
@trustime-more: "\ea1f";
@trustime-file: "\ea20";
@trustime-support: "\ea21";
@trustime-folder: "\ea22";
@trustime-ic_drag_handle_24px: "\ea23";

.trustime-spinner::before {
  content: @trustime-spinner;
}

.trustime-verified::before {
  content: @trustime-verified;
}

.trustime-check::before {
  content: @trustime-check;
}

.trustime-privacy::before {
  content: @trustime-privacy;
}

.trustime-pdf::before {
  content: @trustime-pdf;
}

.trustime-jpg::before {
  content: @trustime-jpg;
}

.trustime-png::before {
  content: @trustime-png;
}

.trustime-zip::before {
  content: @trustime-zip;
}

.trustime-doc::before {
  content: @trustime-doc;
}

.trustime-docx::before {
  content: @trustime-docx;
}

.trustime-logout::before {
  content: @trustime-logout;
}

.trustime-question::before {
  content: @trustime-question;
}

.trustime-cogwheel::before {
  content: @trustime-cogwheel;
}

.trustime-upload-file::before {
  content: @trustime-upload-file;
}

.trustime-bulb-63::before {
  content: @trustime-bulb-63;
}

.trustime-nav-up::before {
  content: @trustime-nav-up;
}

.trustime-nav-down::before {
  content: @trustime-nav-down;
}

.trustime-a-edit::before {
  content: @trustime-a-edit;
}

.trustime-key::before {
  content: @trustime-key;
}

.trustime-bill::before {
  content: @trustime-bill;
}

.trustime-credit-card::before {
  content: @trustime-credit-card;
}

.trustime-users-wm::before {
  content: @trustime-users-wm;
}

.trustime-add-29::before {
  content: @trustime-add-29;
}

.trustime-more::before {
  content: @trustime-more;
}

.trustime-file::before {
  content: @trustime-file;
}

.trustime-support::before {
  content: @trustime-support;
}

.trustime-folder::before {
  content: @trustime-folder;
}

.trustime-ic_drag_handle_24px::before {
  content: @trustime-ic_drag_handle_24px;
}

