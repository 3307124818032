/* --------------------------------

trustime Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'trustime';
  src: url('../fonts/trustime.eot');
  src: url('../fonts/trustime.eot') format('embedded-opentype'), url('../fonts/trustime.woff2') format('woff2'), url('../fonts/trustime.woff') format('woff'), url('../fonts/trustime.ttf') format('truetype'), url('../fonts/trustime.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.trustime {
  display: inline-block;
  font: normal normal normal 1em/1 'trustime';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.trustime-sm {
  font-size: 0.8em;
}
.trustime-lg {
  font-size: 1.2em;
}
/* absolute units */
.trustime-16 {
  font-size: 16px;
}
.trustime-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.trustime-bg-square,
.trustime-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.trustime-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.trustime-ul {
  padding-left: 0;
  list-style-type: none;
}
.trustime-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.trustime-ul > li > .trustime {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.trustime-is-spinning {
  -webkit-animation: trustime-spin 2s infinite linear;
  -moz-animation: trustime-spin 2s infinite linear;
  animation: trustime-spin 2s infinite linear;
}
@-webkit-keyframes trustime-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes trustime-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes trustime-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.trustime-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.trustime-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.trustime-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.trustime-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.trustime-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/
.trustime-spinner::before {
  content: "\ea02";
}
.trustime-verified::before {
  content: "\ea03";
}
.trustime-check::before {
  content: "\ea04";
}
.trustime-privacy::before {
  content: "\ea0a";
}
.trustime-pdf::before {
  content: "\ea0c";
}
.trustime-jpg::before {
  content: "\ea0d";
}
.trustime-png::before {
  content: "\ea0e";
}
.trustime-zip::before {
  content: "\ea0f";
}
.trustime-doc::before {
  content: "\ea10";
}
.trustime-docx::before {
  content: "\ea11";
}
.trustime-logout::before {
  content: "\ea12";
}
.trustime-question::before {
  content: "\ea13";
}
.trustime-cogwheel::before {
  content: "\ea14";
}
.trustime-upload-file::before {
  content: "\ea15";
}
.trustime-bulb-63::before {
  content: "\ea16";
}
.trustime-nav-up::before {
  content: "\ea17";
}
.trustime-nav-down::before {
  content: "\ea18";
}
.trustime-a-edit::before {
  content: "\ea19";
}
.trustime-key::before {
  content: "\ea1a";
}
.trustime-bill::before {
  content: "\ea1b";
}
.trustime-credit-card::before {
  content: "\ea1c";
}
.trustime-users-wm::before {
  content: "\ea1d";
}
.trustime-add-29::before {
  content: "\ea1e";
}
.trustime-more::before {
  content: "\ea1f";
}
.trustime-file::before {
  content: "\ea20";
}
.trustime-support::before {
  content: "\ea21";
}
.trustime-folder::before {
  content: "\ea22";
}
.trustime-ic_drag_handle_24px::before {
  content: "\ea23";
}
